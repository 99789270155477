import React, { useState } from "react";
import { Col, Container, Row, Toast } from "react-bootstrap";
import { Images } from "../Assets/images/images";
import ContactFooter from "../components/ContactFooter";
import Layout from "../components/layout";
import "../Assets/styles/contact.scss";
import { graphql } from "gatsby";
import { imagePathURL } from "../utils/JSONData";
import { Fade } from "react-reveal";
import Scrolltop from "../components/Scrolltotop/scrolltop";
import emailjs from '@emailjs/browser'
// import { token } from '../../gatsby-node'

const ContactPage = ({ data }) => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    country: "",
    message: "",
  })

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    number: "",
    country: "",
    message: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  // const handleSubmit = async (e) => {
  //   try {
  //     e.preventDefault();
  //     const errors = {};
  //     if (!formData.name) {
  //       errors.name = "*Please enter your name.";
  //     }
  //     if (!formData.email) {
  //       errors.email = "*Please enter your email.";
  //     } else {
  //       const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  //       if (!emailRegex.test(formData.email)) {
  //         errors.email = "*Please enter a valid email address.";
  //       }
  //     }
  //     if (!formData.number) {
  //       errors.number = "*Please enter your phone number.";
  //     }
  //     if (!formData.country) {
  //       errors.country = "*Please select a country.";
  //     }
  //     if (!formData.message) {
  //       errors.message = "*Please enter a message.";
  //     }

  //     setValidationErrors(errors);

  //     if (Object.keys(errors).length > 0) {
  //       // showCustomToast("Please fix the form errors", "error");
  //       return;
  //     }
  //     const data = {
  //       data: {
  //         name: formData.name,
  //         email: formData.email,
  //         country: formData.country,
  //         message: formData.message,
  //         number: formData.number,
  //       }
  //     }
  //     // console.log("data", token);
  //     // console.log('data',data)
  //     console.log("formData", formData)

  //     const response = await fetch("https://admin.madhavtechno.com/api/contact-uses", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": "7c2f6819ad8e3a3a2241c29cb5ae471f54fdd4a7e24637473c4e0a9bff9b4e6201f91992d1dd1779d35e9c1454bc666ca107771f249436f7b365acfa3d8863bb53bd9787896caf1c34f191f418ceefc04f72ba4a0e6a7fe85563edfb23724491c57b6cbea0567f8cab00fcf3fd5a16a932ea295db751361d8334ecefae24b9ff",
  //       },
  //       body: JSON.stringify(data),
  //     });
  //     console.log("response", response)
  //     if (response.ok) {
  //       const responseData = await response.json();
  //       console.log("API response data:", responseData);
  //       setFormData({
  //         name: "",
  //         email: "",
  //         number: "",
  //         country: "",
  //         message: "",
  //       });
  //       showCustomToast("submitted successfully", "success");
  //     } else {
  //       console.error("API request failed with status:", response.status);
  //       showCustomToast("Failed to submit the form", "error");
  //     }
  //   } catch (error) {
  //     // console.log("Error", error)
  //     showCustomToast("An error occurred while submitting the form", "error");
  //   }
  // }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const errors = {};
      if (!formData.name) {
        errors.name = "*Please enter your name.";
      }
      if (!formData.email) {
        errors.email = "*Please enter your email.";
      } else {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(formData.email)) {
          errors.email = "*Please enter a valid email address.";
        }
      }
      if (!formData.number) {
        errors.number = "*Please enter your phone number.";
      } else {
        const phoneRegex = /^\d+$/;
        if (!phoneRegex.test(formData.number)) {
          errors.number = "*Please enter a valid phone number.";
        }
      }
      if (!formData.country) {
        errors.country = "*Please select a country.";
      }
      if (!formData.message) {
        errors.message = "*Please enter a message.";
      }

      setValidationErrors(errors);

      if (Object.keys(errors).length > 0) {
        // showCustomToast("Please fix the form errors", "error");
        return;
      }

      const data = {
        data: {
          name: formData.name,
          email: formData.email,
          country: formData.country,
          message: formData.message,
          number: formData.number,
        }
      };

      // First API Call

      console.log("First API URL:", "https://admin.madhavtechno.com/api/contact-uses");
      const firstResponse = await fetch("https://admin.madhavtechno.com/api/contact-uses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "7c2f6819ad8e3a3a2241c29cb5ae471f54fdd4a7e24637473c4e0a9bff9b4e6201f91992d1dd1779d35e9c1454bc666ca107771f249436f7b365acfa3d8863bb53bd9787896caf1c34f191f418ceefc04f72ba4a0e6a7fe85563edfb23724491c57b6cbea0567f8cab00fcf3fd5a16a932ea295db751361d8334ecefae24b9ff",
        },
        body: JSON.stringify(data),
      });

      if (firstResponse.ok) {
        const firstResponseData = await firstResponse.json();
        console.log("First API response data:", firstResponseData);

        // Second API Call
        console.log("Second API URL:", "/api/contact-us");
        // const secondResponse = await fetch("/api/contact-us", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Authorization": "",
        //   },
        //   body: JSON.stringify(data),
        // });

        const secondResponse = await emailjs.send(
          // 'service_cxi1dca', 'template_5vkzfcr',
          process.env.EMAILJS_SERVICE_ID,
          process.env.EMAILJS_TEMPLATE_ID,
          {
            name: formData.name,
            email: formData.email,
            country: formData.country,
            message: formData.message,
            number: formData.number,
          },
          //  's3QEWeUEJnVPTVxI6'
          process.env.EMAILJS_USER_ID
        )

        console.log("Second API response:", secondResponse);

        if (secondResponse) {
          console.log("Second API response data:", secondResponse);


          // Additional handling for the second API response if needed

          // Reset the form data and show success toast
          setFormData({
            name: "",
            email: "",
            number: "",
            country: "",
            message: "",
          });
          showCustomToast("Submitted successfully", "success");
        } else {
          console.error("Second API request failed with status:", secondResponse);
          showCustomToast("Failed to submit the form", "error");
        }
      } else {
        console.error("First API request failed with status:", firstResponse.status);
        showCustomToast("Failed to submit the form", "error");
      }
    } catch (error) {
      console.error("An error occurred while submitting the form", error);
      showCustomToast("An error occurred while submitting the form", "error");
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const showCustomToast = (message, type) => {
    setShowToast(true);
    setToastMessage(message);
    setToastType(type);
  };

  const { allSchedulecall2, allHowtoproceeds, allGetStarted } = data
  return (
    <Layout>
      <div className="contact_bg1">
        <img src={Images.contactBg1} alt="conatct" className="contact_bg-first" />
        <div className="contact_main_content">
          <img className="contact_blueDotImg" src={Images.blueDotImg} alt="blue-dot" />
          <div className="contact_started_form">
            <Container>
              <form onSubmit={handleSubmit}>
                <div className="text_style">{allGetStarted.nodes[0].data.attributes.title}</div>
                <div className="contact_form_sub_text">{allGetStarted.nodes[0].data.attributes.description}</div>
                <Row>
                  <Col xs={12} md={5} className="mr-20">
                    <div className="contact_form_group">
                      <input
                        type="text"
                        className="input-field"
                        // placeholder="Your Name"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                      // required
                      />
                      <label className="input-label" htmlFor="name">Your Name</label>
                      <div className="error-message">{validationErrors.name}</div>
                    </div>
                  </Col>
                  <Col xs={12} md={5}>
                    <div className="contact_form_group">
                      <input
                        type="text"
                        className="input-field"
                        // placeholder="Email address"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        name="email"
                      // required
                      />
                      <label className="input-label" htmlFor="email">Email address</label>
                      <div className="error-message">{validationErrors.email}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={5} className="mr-20">
                    <div className="contact_form_group">
                      <input
                        type="tel"
                        maxLength={10}
                        className="input-field"
                        // placeholder="Phone number"
                        name="number"
                        id="number"
                        value={formData.number}
                        onChange={handleChange}
                      // required
                      />
                      <label className="input-label" htmlFor="number">Phone number</label>
                      <div className="error-message">{validationErrors.number}</div>
                    </div>
                  </Col>
                  <Col xs={12} md={5}>
                    <div className="contact_form_group">
                      <input
                        type="text"
                        className="input-field"
                        // placeholder="Select country"
                        id="country"
                        value={formData.country}
                        onChange={handleChange}
                        name="country"
                      // required
                      />
                      <label className="input-label" htmlFor="country">Select country</label>
                      <div className="error-message">{validationErrors.country}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={11}>
                    <div className="contact_form_group">
                      <textarea
                        className="input-field form_control_textarea"
                        name="message"
                        // placeholder="Your Message"
                        id="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <label className="input-label" htmlFor="message">Your Message</label>
                      <div className="error-message">{validationErrors.message}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      <button className="contact_button" type="submit">
                        {/* SUBMIT */}
                        Connect
                        {/* <span>
                          <img src={Images.WhiteArrow} alt="white arrow"></img>
                        </span> */}
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Container>
          </div>
        </div>
        <div className="contact_proceed_view">
          <img className="contact_whiteDotImg" src={Images.whiteDotImg} alt="white-dot" />
          <div className="contact_proceed_header_text">
            <span style={{ display: "inline-block" }}>
              How to Proceed
              <div style={{ display: "flex", justifyContent: "center" }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginRight: "10px" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginLeft: "10px" }}> </div>
              </div>
            </span>
          </div>
          <Container>
            <Row className="contact_row">
              {allHowtoproceeds.nodes[0].data.map((item, i) => {
                return <Col xs={12} md={12} lg={6} className="" key={i}>
                  <Fade left>
                    <div className='contact_block_group bg01' style={{ backgroundImage: `url(${imagePathURL + item.attributes.image.data.attributes.url})` }}>
                      <div className="contact_block_title">{item.attributes.title}</div>
                      <div className="contact_block_subcontent">
                        {item.attributes.description}
                      </div>
                    </div>
                  </Fade>
                </Col>
              })}
            </Row>
            <div className="contact_schedule">
              <button className="contact_button contact_schedule_button" onClick={() => window.open('https://calendly.com/hiteshkachhadiya', '_blank')} >
                {allSchedulecall2.nodes[0].data.attributes.btnTxt}
              </button>
            </div>
            <div className="contact_block_subcontent contact_proceed_text">
              {allSchedulecall2.nodes[0].data.attributes.description}
            </div>
          </Container>
        </div>
      </div>
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        className={`custom-toast ${toastType}`}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <ContactFooter />
      <Scrolltop />
    </Layout>
  );
};
export const query = graphql`
  {
    allHowtoproceeds {
      nodes {
        data {
          attributes {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allSchedulecall2 {
      nodes {
        data {
          attributes {
            description
            btnTxt
          }
        }
      }
    }

    allGetStarted {
      nodes {
        data {
          attributes {
            title
            description
          }
        }
      }
    }
  }
`;
export default ContactPage;
