import React from "react";
import "./styles.scss";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, navigate, useStaticQuery } from "gatsby";

const ContactFooter = () => {
  const { allLocations } = useStaticQuery(query);

  const handlemapClick = () => {
    window.open("https://www.google.com/maps/place/City+Centre/@21.206154,72.8373404,21z/data=!4m15!1m8!3m7!1s0x3be04efbd2f1710d:0xf312ef8fb400d605!2sLal+Darwaja+Station+Rd,+Surat,+Gujarat!3b1!8m2!3d21.2068595!4d72.8401015!16s%2Fg%2F1tdr6n65!3m5!1s0x3be04ef0b4d10bf1:0xca9d2f2ae479108!8m2!3d21.2061587!4d72.8373126!16s%2Fg%2F1pzwl8msx?authuser=0&entry=ttu", "_blank");
  }

  const handleAboutClick = () => {
    navigate('/about-us')
  }

  const handleContactClick = () => {
    navigate('/contact')
  }

  return (
    <div className="contactfooter_main">
      <Container>
        <Row>
          <Col lg={5} xs={12} md={5} className={"mb-3"}>
            <div className="contactfooter_title">Location</div>
            <div className="contactfooter_sub">
              <span onClick={handlemapClick} style={{cursor:'pointer'}}>
              {allLocations.nodes[0].data[2].attributes.name}
              </span>
            </div>
          </Col>
          {/* <Col xs={1} md={1}></Col> */}
          <Col lg={4} xs={12} md={4} className={"mb-3"}>
            <div className="contactfooter_title">Contact</div>
            <div className="contactfooter_sub" style={{ color: "#00467F" }}>
              <span onClick={() => navigate("mailto:contact@madhavtechno.com")} style={{ cursor: 'pointer' }}>
                {allLocations.nodes[0].data[1].attributes.name}
              </span>
            </div>
            <div className="contactfooter_sub" style={{ color: "#00467F" }} >
              <span onClick={() => window.location.href = "tel:+91-123-456-7891"}>
                <span style={{ color: "#000000" }} >IN</span>
                <span style={{ cursor: 'pointer' }}>
                  {allLocations.nodes[0].data[0].attributes.name.replace("IN", "")}
                </span>
              </span>
            </div>

          </Col>
          {/* <Col lg={3} xs={12} md={3} className={"mb-3"}>
            <div className="contactfooter_title">About Us</div>
            <div className="contactfooter_sub">
              <span onClick={handleAboutClick} style={{ cursor: 'pointer' }}>
                About
              </span>
            </div>
            <div className="contactfooter_sub">
              <span onClick={handleContactClick} style={{ cursor: 'pointer' }}>
                Contact
              </span>
            </div>
          </Col> */}
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className="d-flex">
              <div className="contactfooter_last_row_icon">©</div>
              <div className="contactfooter_last_row">
                2023 MadhavTechnologies - Web & Mobile App Development Company |
                Privacy | Sitemap
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export const query = graphql`
  {
    allLocations {
      nodes {
        data {
          attributes {
            name
            link
            icon {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default ContactFooter;